<template>
    <b-overlay :show="pageBusy" variant="transparent">
        <b-card>
            <div class="row pt-0">
                <div class="col-12 pb-1">
                    <span class="font-weight-bolder">Filters</span>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Date Range</label>
                    <flat-pickr
                        v-model="range"
                        class="form-control"
                        :config="{ mode: 'range'}"
                        />
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Mode</label>
                    <v-select  v-model="mode"  :options="modeOptions" :clearable="false"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6" v-if="mode.value != 'submitted'">
                    <label>State</label>
                    <v-select  v-model="status" :options="statusOptions"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Property title</label>
                    <input v-model="searchTitle" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-3 col-12 col-md-6 pt-2 pt-lg-2" @click="filterFeedback()">
                    <span class="btn btn-primary"> <feather-icon icon="SearchIcon" /> Search</span>
                </div>
            </div>
        </b-card>
        <b-card>
            <b-overlay :show="itemBusy">
                <b-table
                    class="rounded border"
                    :items="items"
                    :fields="fields"
                    :per-page="10"
                    :current-page="1"
                    responsive
                >

                    <template #head()="scope">
                        <div class="text-nowrap text-primary">
                        {{ scope.label }}
                        </div>
                    </template>
                    <template #cell(type)="data">
                        <span class="text-nowrap badge badge-primary" v-if="data.item.id">
                           {{getCatType(data.item.propertyType)}}
                        </span>
                    </template>
                    <template #cell(title)="data">
                        <span class="three-line-ellipsis" v-if="data.item.id">
                           {{data.value}}
                        </span>
                        <div v-else class="pt-2">
                        
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-badge :variant="displayStatus[1][data.value]">
                                {{ displayStatus[0][data.value] }}
                            </b-badge>
                        </span>
                    </template>
                    <template #cell()="data">
                        <div class="text-nowrap pt-1">
                            {{data.value}}
                        </div>
                    </template>
                    <template #cell(businessType)="data">
                        <div class="text-nowrap pt-1 small" v-if="data.item.id">
                            {{data.value == 3? 'Corporate Business': 'Private Business'}}
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(updatedAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <!-- <div class="text-nowrap" v-if="data.item.id">
                            <b-button size="sm" pill variant="outline-primary" v-b-toggle.validate @click="triggerProcessing(data.item)">
                        Review
                            </b-button>
                        </div> -->

                        <b-dropdown
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            v-if="data.item.id"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                ></feather-icon>
                            </template>
                            <b-dropdown-item @click="triggerProcessing(data.item, 1)">
                                <feather-icon
                                    icon="EyeIcon"
                                />
                                Preview
                            </b-dropdown-item>
                            <!-- <b-dropdown-item @click="showCampaign(data.item)">
                                <feather-icon
                                    icon="EyeIcon"
                                />
                                Preview
                            </b-dropdown-item> -->
                            <b-dropdown-item v-if="mode.value == 'draft'" @click="triggerProcessing(data.item, 2)">
                                <feather-icon
                                    icon="CheckIcon"
                                />
                                Approve / Reject
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template #cell(image)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-img :src="data.value" style="height: 50px" rounded class="cursor-pointer" @click="triggerViewImage([data.value])">

                            </b-img>
                        </span>
                    </template>
                </b-table>
            </b-overlay>
            <b-modal id="view-images" ok-only hide-header size="lg" centered no-close-on-backdrop no-close-on-esc body-bg-variant="dark">
                <div style="min-height: 400px;">
                    <b-carousel id="carousel-main" style="text-shadow: 0px 0px 2px #000" :interval="7000" :controls="images.length > 1" :indicators="images.length>1" img-height="480px">
                        <b-carousel-slide class="display-image-holder" v-for="(item, index) in images" :key="index" :text="`${item}`" :img-src="item" />
                    </b-carousel>
                </div>
            </b-modal>
            <b-modal id="view-property" ok-only title="View Property Detail" size="xl" centered no-close-on-backdrop no-close-on-esc>
               <div class="row">
                   <div class="col-12" style="min-height: 600px;">
                       <iframe class="w-100" height="100%" :src="`${link}/preview-property/data-only/property?id=${account.id}&mode=${mode.value}`" frameborder="0"></iframe>
                       <!-- <property :property="account" /> -->
                   </div>
               </div>
            </b-modal>
            <div class="d-flex p-1">
                <div class="w-100">
                    Page <span class="text-primary"> {{currentPage}}</span> of <span class="text-primary">{{Math.ceil(totalRows/10)}}</span>
                </div>
                <div class="w-100">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="10"
                        aria-controls="users-table"
                        align="right"
                        size="sm"
                        class="my-0"
                        @page-click="loadNewPage"
                    />
                </div>
            </div>
            <b-sidebar
                shadow
                id="validate"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                backdrop
                v-model="showSidebar"
                right
                title="Property Approval"
                title-background="primary"
                no-close-on-backdrop
                >
                <template #footer="{ hide }">
                    <div class="d-flex align-items-center p-1">
                        <!-- @@TODO Make the address dynamic on production  -->
                        <div v-if="reviewMode == 2" class="w-100">
                            <h4 class="text-primary mt-2 pt-2 border-top">Add review</h4>
                            <div class="d-flex justify-content-between">
                                <b-form-radio v-model="applicationStatus" value="1" class="w-100">
                                    Approve
                                </b-form-radio>
                                <b-form-radio v-model="applicationStatus" value="2" class="w-100">
                                    Decline
                                </b-form-radio>
                            </div>
                            <div v-if="applicationStatus == 2" class="mt-1">
                                <label class=""> Motive </label>
                                <v-select  v-model="user.status" :clearable="false"  :options="reviewOptions"></v-select>
                                <div>
                                    <label class="mt-2"> Description </label>
                                    <b-textarea v-model="user.text">
                                    </b-textarea>
                                </div>
                            </div>
                            <div class="mt-2">
                                <b-button rounded variant="primary" @click="addReview(hide)" :disabled="!applicationStatus || (applicationStatus == 2 && (!user.status.value || !user.text.trim().length))">
                                    Submit
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
                <template>
                    <div>
                        <div class="p-2 border-bottom border-top">
                            <h4 class="text-primary">Property Information</h4>
                            <h5 class="font-weight-bolder">Title</h5>
                            <span class="text-primary font-weight-bolder">{{account.title}}</span>
                            <h5 class="font-weight-bolder mt-2">Display image</h5>
                            <b-img rounded style="max-height: 400px; max-width: 100%" :src="account.image" class="cursor-pointer" @click="triggerViewImage([account.image])">

                            </b-img>
                            <h5 class="font-weight-bolder mt-2">Category</h5>
                            <span class="text-primary font-weight-bolder">{{account.type? account.type.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">Description</h5>
                            <span class="text-primary font-weight-bolder">{{account.description}}</span>
                            <h5 class="font-weight-bolder mt-2">Price</h5>
                            <span class="font-weight-bolder">{{currency(account.price)}}</span> <small class="text-primary">XAF</small>
                            <h5 class="font-weight-bolder mt-2">Duration</h5>
                            <span class="text-primary font-weight-bolder">{{getDuration(account.duration)}}</span>
                            <h5 class="font-weight-bolder mt-2">Address</h5>
                            <span class="text-primary font-weight-bolder">{{getDuration(account.address)}}</span>
                            <h5 class="font-weight-bolder mt-2">Neighborhood</h5>
                            <span class="text-primary font-weight-bolder">{{account.neighborhood? account.neighborhood.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">City</h5>
                            <span class="text-primary font-weight-bolder">{{account.city? account.city.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">Other images</h5>
                            <div>
                                <div class="d-inline-block mt-1 mr-1" v-for="item in account.images" :key="item">
                                    <b-img :src="item" rounded height="100px" class="cursor-pointer" @click="triggerViewImage(account.images)">

                                    </b-img>
                                </div>
                            </div>

                        </div>
                        <h4 class="p-2 text-primary" v-if="account.reviewHistory && account.reviewHistory.length">Previous Reviews</h4>
                        <b-list-group>
                            <b-list-group-item v-for="(review, j) in account.reviewHistory" :key="j">
                                <div class="alert alert-secondary rounded p-1 mb-0">
                                    <div>
                                        <b-badge :variant="displayStatus[1][review.status]">
                                            {{ displayStatus[0][review.status] }}
                                        </b-badge>
                                    </div>
                                    <div v-if="review.status != 5">
                                        <p class="text-primary font-weight-bold">{{ review.reason[0] }}</p>
                                        {{review.message}}
                                    </div>
                                    <div class="d-flex mt-1 border-top p-0">
                                        <div class="w-100 d-flex align-items-center">
                                            <div>
                                                <b-avatar></b-avatar>
                                            </div>
                                            <div class="w-100 pl-1">
                                                <div>{{ review.name }}</div>
                                                <div>{{ review.email }}</div>
                                            </div>
                                            <!-- <small>{{review.reviewer}}</small> -->
                                        </div>
                                        <div class="text-right text-nowrap">
                                            <small class="text-primary">
                                                {{ dayjs(review.date).format("MMM D, YYYY h:mm A") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </template>
            </b-sidebar>
        </b-card>
    </b-overlay>


</template>

<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import dayjs from "dayjs";
import flatPickr from 'vue-flatpickr-component'
import Utils from '@/utils/index';
import Vue from "vue";
import API from './api'
import Property from './SingleCampaign.vue'

const vm = new Vue({});
const toast = useToast()
export default {
    name: 'Feedback',
    components: {
        flatPickr,
        vSelect,
        Property
    },
    data() {
        return {
            reviewMode: 1,
            currency: Utils.formatCurrency,
            campaignTypes:{
                '1': 'Recursive',
                '2': 'Normal',
                '3': 'Ends on target attained',
                '5': 'Never ends',
                // rejected: -10,
            },
            link: 'https://www.amrefinance.com',
            ftoken: '',
            dayjs: dayjs,
            currentPage: 1,
            totalRows: 10,
            range: '',
            reviews: [],
            searchDate: '',
            dateRange: '',
            searchTitle: '',
            status: '',
            durations: [
                {title: 'Hourly', value: 5 },
                {title: 'Daily', value: 6 },
                {title: 'Weekly', value: 7 },
                {title: 'Monthly', value: 8 },
                {title: 'Yearly', value: 9 },
                {title: 'One Time', value: 11 },
                {title: 'Life Time', value: 10 },
            ],
            displayStatus:[
                {
                    '1':'Pending', 
                    '5':'Approved', 
                    '-1':'Rejected', 
                    '-5':'Permanently rejected', 
                    'undefined': 'System Status'
                },
                {
                    '1':'dark', 
                    '5': 'success', 
                    '-1': 'danger', 
                    '-5': 'danger', 
                    'undefined': 'success'
                },
            ],
            statusOptions: [
                {label:'Pending',"value":1},
                {label:'Approved',"value":5},
                {label:'Rejected',"value":-1},
                {label:'Permanently Rejected',"value":-5},
            ],
            modeOptions: [
                {label:'Published',"value":'submitted'},
                {label:'Not published',"value": 'draft'},
            ],
            mode: '',
            user: {
                text:'',
                status: '',
                id: ''
            },
            account: {
                reviewHistory: []
            },
            checked: '',
            images: [],
            frMotifs: {
                '1': 'Inappropriate images',
                '2': 'Images inappropriées',
                '3': 'Compte non vérifié',
                '4': 'Informations incomplètes',
            },
            reviewOptions: [
                {label:'Invalid information',"value":1},
                {label:'Inappropriate images',"value": 2},
                {label:'Account not verified',"value": 3},
                {label:'Incomplete information',"value": 4},
                ],
            items: [],
            fields: [
                {key: 'actions', label: 'Actions'},
                {key: 'title', label: 'Title'},
                {key: 'status', label: 'Status'},
                {key: 'type', label: 'Type'},
                // {key: 'shortDesc', label: 'Description'},
                {key: 'image', label: 'Display image'},
                {key: 'createdAt', label: 'Created At'},
                {key: 'updatedAt', label: 'Updated At'},
            ],
            categories: [],
            searchParams: {},
            itemBusy: false,
            pageBusy: false,
            showSidebar: false,
            retriedCategories: 0,
            applicationStatus: 0,
        }
    },
    watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10) {
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
        applicationStatus(data) {
            // if(data == 1) {
            //     // this.user.status = {
            //     //     value: 3,
            //     //     label: 'Run',
            //     // }
            //     // this.user.text = '';
            // }else{
            //     this.user.status = '';
            // }
        },
        mode(data){
            // this.setStates();
            this.getCampaigns();
        }
    },
    created() {
        this.items = Utils.completeTable();
        // this.getCampaigns();
    },
    mounted() {
        this.mode = this.modeOptions[1];
        if(process.env.NODE_ENV == 'development') this.link = "http://localhost:3000";
        else if(process.env.NODE_ENV == 'staging') this.link = "https://test.amrefinance.com";
        this.ftoken = vm.$fileAccessToKen;
        this.getCategories();
    },
    methods: {
        getDuration(e){
            const duration = this.durations.find(d => d.value == e);
            if(duration){
                return duration.title
            }
            return "";
        },
        getCatType(id){
            const categories = this.categories.find(c=> c.typeId == id);
            if(categories) return categories.name;
            return '';
        },
        getCategories() {
            // this.pageBusy = true;
            if(this.retriedCategories > 10){
                return;
            }
            API.getCategories({ pageSize: 100}, false, true).then(res => {
                if(res.success && res.data) {
                    this.retriedCategories = 0;
                    this.categories = res.data.list;
                }else{
                    this.retryGetCategoriesId();
                }
            }).catch(() => {
                this.retryGetCategoriesId();
            })
        },
        retryGetCategoriesId(){
            this.retriedCategories+=1;
            setTimeout(() => {
                this.getCategories();
            }, 10000);
        },
        setStates(){
            if(this.mode.value == 'draft'){
                this.statusOptions = [
                    // {label:'Not submitted',"value":1},
                    {label:'Pending',"value":1},
                    {label:'Approved',"value":5},
                    // {label:'Initialized',"value":20},
                    {label:'Rejected',"value":-1},
                    {label:'Permanently Rejected',"value":-1},
                ]
            }else{
                this.statusOptions = [
                    {label:'Approved',"value":100},
                    {label:'Running',"value":300}, // running
                    {label:'Paused',"value":400},
                    {label:'Stopped',"value":-300},
                    {label:'Pending Deletion',"value":-500},
                ]
            }
            this.status = '';
        },
        showCampaign(data = {}) {
            this.account = data;
            this.$bvModal.show('view-property');
        },
        triggerViewImage(image = []) {
            this.images = image;
            this.$bvModal.show('view-images');
        },
        runCampaign(e) {
            API.runCampaign({id: this.account.id}).then(res=>{
                if(res.success) {
                    this.user.status = '';
                    this.user.text = '';
                    setTimeout(()=>{
                        this.getCampaigns(this.currentPage);
                    },200);
                    e();
                    this.showSidebar = false;
                    this.showToast('Property started successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        pauseCampaign(e) {
            API.pauseCampaign({id: this.account.id}).then(res=>{
                if(res.success) {
                    this.user.status = {};
                    this.user.text = '';
                    setTimeout(()=>{
                        this.getCampaigns(this.currentPage);
                    },200);
                    e();
                    this.showSidebar = false;
                    this.showToast('Property paused successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        deleteCampaign(e) {
            API.deleteCampaign({id: this.account.id}).then(res=>{
                if(res.success) {
                    this.user.status = {};
                    this.user.text = '';
                    setTimeout(()=>{
                        this.getCampaigns(this.currentPage);
                    },200);
                    this.showSidebar = false;
                    this.showToast('Property deleted successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        getMotif(reason){
            let r = reason.label;
            if(this.account.lang == 'fr'){
                r = this.frMotifs[reason.value];
            }
            return [r];
        },
        addReview(e) {
            const data = {
                isPaid: this.account.type.isPaid,
                id: this.user.id,
                message: '',
                reason: ['Approved'],
                status: this.applicationStatus == 1? 5: -1
            }
            if(this.applicationStatus == 1){
                data.message = 'Approved';
                data.reason = ['Approved'];
            }else{
                data.message = this.user.text.trim();
                data.reason = this.getMotif(this.user.status);
            }
            API.reviewProperty({...data}).then(res=>{
                if(res.success && res.data) {
                    this.resetReview();
                    setTimeout(()=>{
                        this.getCampaigns(this.currentPage);
                    },200);
                    e();
                    this.showToast('Review Added successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        resetReview(){
            this.user = {
                text:'',
                status: '',
                id: ''
            }
            this.applicationStatus = 0;
        },
        triggerProcessing(data, mode = 1) {
            this.reviewMode = mode;
            const params = {
                id:data.propertyId,
                mode: data.reviewHistory? 'draft' : 'submitted'
            }
            API.getProperty(params).then(res=>{
                if(res.success){
                    this.account = res.data;
                    this.user.id = res.data.propertyId;
                    this.showSidebar = true;
                }
            }).catch(err=>{
                this.showToast('Failed to load property. Please try again');
                console.error(err);
            });
            // this.$bvModal.show('validate');
        },
        loadNewPage(e,page) {
            console.log(page)
            e.preventDefault();
            this.getCampaigns(page);
        },
        getCampaigns(page = 1) {
            // this.pageBusy = true;
            API.getCampaigns({...this.searchParams, page, mode: this.mode.value || 'submitted'}).then(res => {
                this.pageBusy = false;
                if(res.success && res.data) {
                    this.currentPage = res.data.currentPage;
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total || 10;
                }
            }).catch(() => {
                this.pageBusy = false;
                this.showToast();
            })
        },
         filterFeedback() {
            if(!this.searchDate && !this.searchTitle && !this.status) {
                this.searchParams = {};
            }else{
                this.searchParams = {};
                if(this.searchTitle) {
                    this.searchParams.title = this.searchTitle;
                }
                if(this.status && this.status.value && this.mode.value != 'submitted') {
                    this.searchParams.status = this.status.value;
                }
                if(this.searchDate && this.searchDate.from) {
                    this.searchParams.startDate = this.searchDate.from + ' 00:00:00';
                    if(!this.searchDate.to) {
                        this.this.searchDate.to = this.searchDate.from
                    }
                    this.searchParams.endDate = this.searchDate.to + ' 23:59:59';
                }
            }
            this.getCampaigns(1);
            this.currentPage = 1;
        },
        showToast(message, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message || 'Error fetching applications',
                    icon: icon || 'InfoIcon',
                    variant: variant || 'danger',
                },
            })
        },
        showModal(feedback) {
            this.$bvModal.msgBoxOk(feedback, {
            title: 'Feedback',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.feedback = value
            })
            .catch(err => {
                // An error occurred
            })
        },
        markAsProcessed(feedbackId) {
            if(feedbackId) {
                this.feedbacks = {};
                // this.pageBusy = true;
                API.markAsProcessed({_id: feedbackId}).then(res => {
                    this.pageBusy = false;
                    if(res.success) {
                        this.items = this.items.map(item =>{
                            if(item.id == feedbackId) {
                                item.status = res.data.status;
                            }
                            return item;
                        })
                        this.showToast('Feedback Marked as processed', 'CheckIcon', 'success');
                    }
                }).catch(() => {
                    this.pageBusy = false;
                    this.showToast('Error processing feedback')
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>

    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .feedback-content{

        max-width: 100px !important;
    }
    .feedback-content:hover{
        transform: scale(1.2);
    }
    #carousel-main{
        text-align: center !important;
        img{
            height: 480px !important;
            width: auto !important;
            margin: auto
        }
    }
</style>