import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

// let path =  `${baseUrl.main}/${baseUrl.envName != 'development'? "cs/": ''}cs/v1/`;
// let path = `http://localhost:6050/v1/`;
let path = `${baseUrl.main}/v1/`;

// this is for globally used APIs only
export default {
  getCampaigns: (params = {}) => request.post(path + "properties/bo/list?mode=" + params.mode + '&limit=10&page=' + params.page, params),
  getProperty: (params = {}) => request.get(path + `properties/bo/${params.id}?mode=` + params.mode, params),
  getCategories: (params = {}, hideLoadingSpinner = false, hideErrorMsg = false) => request.post(path + "properties/bo/type/list", params, {hideLoadingSpinner, hideErrorMsg}),
  addCategory: (params = {}) => request.post(path + "properties/bo/type/new", params),
  toggleVisibility: (params = '') => request.post(path + "properties/bo/type/toggle/" + params),
  removeCategory: (params = {}) => request.put(path + "properties-category/bo/remove/" + params._id),
  editCategory: (params = {}) => request.put(path + "properties/bo/type/" + params.typeId, params),
  reviewProperty: (params = {}) => request.post(path + "properties/bo/review/" + params.id, params),
  runCampaign: (params = {}) => request.put(path + "properties/bo/run/" + params.id, params),
  pauseCampaign: (params = {}) => request.put(path + "properties/bo/pause/" + params.id, params),
  deleteCampaign: (params = {}) => request.put(path + "properties/bo/delete/" + params.id, params),
  markAsProcessed: (params = {}) => request.post(path + "admin/mark-processed", params),
  getRefundableCampaigns: (params = {}, page = 1, limit = 12) => request.post(path + "properties/bo/refunds/list?page=" + page + "&limit=" + limit, params),
  getCampaignRefundStatus: (campaignId = null) => request.post(path + "properties/bo/refunds/info/" + campaignId),
  triggerRefundOnCampaign: (campaignId = null) => request.put(path + "properties/bo/refunds/" + campaignId),
  // review: (params = {}) => request.post(baseUrl.main + "/ba021/v1/rnv/user/applications/review", params)
}