export default [
    {
      path: '/apps/calendar',
      name: 'apps-calendar',
      component: () => import('@/views/apps/calendar/Calendar.vue'),
    },

    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    {
      path: '/apps/email',
      name: 'apps-email',
      component: () => import('@/views/apps/email/Email.vue'),
      meta: {
        roles:[101, 103],
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
      },
    },
    {
      path: '/apps/email/:folder',
      name: 'apps-email-folder',
      component: () => import('@/views/apps/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'apps-email',
      },
      beforeEnter(to, _, next) {
        if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/apps/email/label/:label',
      name: 'apps-email-label',
      component: () => import('@/views/apps/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'apps-email',
      },
      beforeEnter(to, _, next) {
        if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
        else next({ name: 'error-404' })
      },
    },

    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/todo',
      name: 'apps-todo',
      component: () => import('@/views/apps/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/apps/todo/:filter',
      name: 'apps-todo-filter',
      component: () => import('@/views/apps/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'apps-todo',
      },
      beforeEnter(to, _, next) {
        if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/apps/todo/tag/:tag',
      name: 'apps-todo-tag',
      component: () => import('@/views/apps/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'apps-todo',
      },
      beforeEnter(to, _, next) {
        if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
        else next({ name: 'error-404' })
      },
    },

    // *===============================================---*
    // *--------- CHAT  ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/chat',
      name: 'apps-chat',
      component: () => import('@/views/apps/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },

    // *===============================================---*
    // *--------- ECOMMERCE  ---------------------------------------*
    // *===============================================---*
    {
      path: '/apps/e-commerce/shop',
      name: 'apps-e-commerce-shop',
      component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Shop',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Shop',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps/e-commerce/wishlist',
      name: 'apps-e-commerce-wishlist',
      component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
      meta: {
        pageTitle: 'Wishlist',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Wishlist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps/e-commerce/checkout',
      name: 'apps-e-commerce-checkout',
      component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
      meta: {
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps/e-commerce/:slug',
      name: 'apps-e-commerce-product-details',
      component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
      meta: {
        pageTitle: 'Product Details',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Shop',
            active: true,
          },
          {
            text: 'Product Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/properties/manage',
      name: 'properties-manage',
      component: () => import('@/views/properties/ManageProperties.vue'),
      meta: {
        pageTitle: 'Properties',
        contentClass: 'all-properties-class',
        breadcrumb: [
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transactions',
      redirect: '/transactions/backings'
    },
    {
      path: '/transactions/backings',
      name: 'transactions-backings',
      component: () => import('@/views/Transactions/Backings.vue'),
      meta: {
        pageTitle: 'Transactions',
        contentClass: 'all-backing-transactions-class',
        breadcrumb: [
          {
            text: 'Backings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/properties/categories',
      name: 'properties-categories',
      component: () => import('@/views/properties/Categories.vue'),
      meta: {
        pageTitle: 'Properties',
        contentClass: 'all-properties-class',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations/countries',
      name: 'locations-countries',
      component: () => import('@/views/locations/Countries.vue'),
      meta: {
        pageTitle: 'Locations',
        contentClass: 'all-countries-class',
        breadcrumb: [
          {
            text: 'Countries',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations/regions',
      name: 'locations-regions',
      component: () => import('@/views/locations/Regions.vue'),
      meta: {
        pageTitle: 'Locations',
        contentClass: 'all-regions-class',
        breadcrumb: [
          {
            text: 'Regions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations/cities',
      name: 'locations-cities',
      component: () => import('@/views/locations/Cities.vue'),
      meta: {
        pageTitle: 'Locations',
        contentClass: 'all-cities-class',
        breadcrumb: [
          {
            text: 'Cities',
            active: true,
          },
        ],
      },
    },
    {
      path: '/locations/neighborhoods',
      name: 'locations-neighborhoods',
      component: () => import('@/views/locations/Neighborhood.vue'),
      meta: {
        pageTitle: 'Locations',
        contentClass: 'all-neighborhoods-class',
        breadcrumb: [
          {
            text: 'Neighborhoods',
            active: true,
          },
        ],
      },
    },
    {
      path: '/properties/refunds',
      name: 'properties-refunds',
      component: () => import('@/views/properties/Refunds.vue'),
      meta: {
        pageTitle: 'Property Refunds',
        contentClass: 'user-management-verification-class',
        breadcrumb: [
          {
            text: 'Refunds',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companies/list',
      name: 'companies-list',
      component: () => import('@/views/companies/CompaniesList.vue'),
      meta: {
        pageTitle: 'Checklists',
        contentClass: 'companies-list',
        breadcrumb: [
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companies/categories',
      name: 'companies-categories',
      component: () => import('@/views/companies/Categories.vue'),
      meta: {
        pageTitle: 'Checklist categories',
        contentClass: 'companies-categories',
        breadcrumb: [
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companies/requests',
      name: 'companies-requests',
      component: () => import('@/views/companies/Requests.vue'),
      meta: {
        pageTitle: 'Requests',
        contentClass: 'companies-request-list',
        breadcrumb: [
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('@/views/welcome/Welcome.vue'),
      meta: {
        pageTitle: 'ATLAZ Group',
        contentClass: 'welcome',
        breadcrumb: [
          {
            text: 'Welcome',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/verification',
      name: 'users-verification',
      component: () => import('@/views/users/UserVerification.vue'),
      meta: {
        pageTitle: 'User Management',
        contentClass: 'user-management-verification-class',
        breadcrumb: [
          {
            text: 'Verification',
            active: true,
          },
        ],
      },
    },

    // *===============================================---*
    // *--------- USER MANAGER ---- ---------------------------------------*
    // *===============================================---*


     // *===============================================---*
    // *--------- FILE MANAGER ---- ---------------------------------------*
    // *===============================================---*

     // *===============================================---*
    // *--------- Cortex -------------------------------*
    // *===============================================---*



    // *===============================================---*
    // *--------- EDU stuffs -------------------------------*
    // *===============================================---*
  ]
