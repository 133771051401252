import Vue from "vue";
import request from "./request";
import baseUrl from "./baseUrl";

// this is for globally used APIs only

const convertToBlob = async image=>{
  // const response = await fetch(image)
  // const blob = await response.blob();
  // const extension = image.name.split('.').pop();
  
  // const blob = new Blob([image], image.type);
  // console.log(blob, extension);
  // const formData = new FormData();
  // formData.append('images', blob);
  // return extension;
  
}

export default {
  login: (params = {}) => request.post(baseUrl.main + "/v1/user/bo/login", params, { hideLoadingSpinner: false }),
  getTmpToken: (params = {}) => request.post(baseUrl.main + "/ba021/v1/auth/dynamic", params, { hideLoadingSpinner: true }),
  // called when the user already has token
  async setDynamicToken(params = {}) {
    // make sure it's not set twice
    if (Vue.prototype.$fileAccessToKen) {
      return;
    }

    const tmpTokenResp = await request.post(baseUrl.main + "/ba021/v1/auth/dynamic", params, { hideLoadingSpinner: true });

    if (tmpTokenResp && tmpTokenResp.success) {
      Vue.prototype.$fileAccessToKen = tmpTokenResp.data.token;
    }
  },
  imageUpload: async (image = {}) => {
    const formData = new FormData();
    formData.append('images', image);
   
  //  return request.post(`http://localhost:6051/upload`, formData, { hideLoadingSpinner: true })
   return request.post(`${baseUrl.cdn}/upload`, formData, { hideLoadingSpinner: true })
  },
}
