<template>
    <b-overlay :show="pageBusy" variant="transparent">
        <b-card>
            <div class="row pt-0">
                <div class="col-12 pb-1">
                    <span class="font-weight-bolder">Filters</span>
                </div>
                <div class="col-lg-4 col-12 col-md-6">
                    <label>Date Range</label>
                    <flat-pickr
                        v-model="range"
                        class="form-control"
                        :config="{ mode: 'range'}"
                        />
                </div>
                <!-- <div class="col-lg-4 col-12 col-md-6">
                    <label>State</label>
                    <v-select  v-model="status"  :options="statusOptions"></v-select>
                </div> -->
                <!-- <div class="col-lg-4 col-12 col-md-6">
                    <label>Name</label>
                    <input v-model="searchName" type="text" class="form-control" placeholder="">
                </div> -->
                <div class="col-lg-4 col-12 col-md-6 pt-2 pt-lg-2">
                    <b-button variant="primary" @click="filterRegions()" disabled> <feather-icon icon="SearchIcon" /> Search</b-button>
                </div>
            </div>
        </b-card>
        <b-card>
            <div class="pb-1">
              <b-button @click="triggerAddRegion()" variant="primary">Add Region</b-button>
            </div>
            <b-modal id="add-region-modal" centered ok-title="Save" @ok="submittedRegion" :title="region.id ? 'Update Region': 'Create Region'" no-close-on-backdrop no-close-on-esc>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-1">
                          <label for="">Name</label> <small class="text-danger">*</small>
                          <b-form-input v-model="region.name" autofocus />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mt-1">
                          <label for="">French name</label>
                          <b-form-input v-model="region.frName" />
                        </div>
                    </div>
                    <div class="col-12">
                      <div class="mt-1">
                        <label for="">Country</label> <small class="text-danger">*</small>
                        <v-select
                          v-model="region.country"
                          :options="countries"
                          :reduce="e=> e.value"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                        <div class="mt-1">
                          <label for="">Description</label>
                          <b-form-textarea v-model="region.desc" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mt-1">
                          <label for="">French description</label>
                          <b-form-textarea v-model="region.frDesc" />
                        </div>
                    </div>

                </div>
            </b-modal>
            <b-overlay :show="itemBusy">
                <b-table
                    class="rounded border"
                    :items="items"
                    :fields="fields"
                    :per-page="10"
                    :current-page="1"
                    responsive
                >

                    <template #head()="scope">
                        <div class="text-nowrap text-primary">
                        {{ scope.label }}
                        </div>
                    </template>
                    <template #cell(name)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <div class="d-flex align-items-center">
                                <div class="pr-1">
                                    <b-avatar variant="primary" icon="people" v-if="data.item.image"></b-avatar>
                                    <b-avatar variant="primary" icon="people" v-else>
                                      <template #default>
                                        <div>
                                          <feather-icon icon="TargetIcon"></feather-icon>
                                        </div>
                                      </template>
                                    </b-avatar>
                                </div>
                                <div class="w-100">
                                    {{data.value}} / {{data.item.frName}}
                                </div>
                            </div>
                        </span>
                    </template>
                    <template #cell(status)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-badge v-if="data.value == 1" variant="success">
                                Visible
                            </b-badge>
                            <b-badge variant="danger" v-else>
                                Hidden
                            </b-badge>
                        </span>
                    </template>
                    <template #cell()="data">
                        <div class="text-nowrap pt-1">
                            {{data.value}}
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ date(data.value) }}
                        </div>
                    </template>
                    <template #cell(updatedAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ date(data.value) }}
                        </div>
                        <div v-else class="p-1">

                        </div>
                    </template>
                    <template #cell(actions)="data">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        v-if="data.item.id"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item @click="triggerEditRegion(data.item)">
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="triggerToggleVisibility(data.item.regionId)">
                          <feather-icon icon="TrashIcon"  />
                          <span class="align-middle ml-50">Toggle visibility</span>
                        </b-dropdown-item>
                        <b-dropdown-item :to="'/properties/manage?regionId=' + data.item.regionId">
                          <feather-icon icon="EyeIcon"  />
                          <span class="align-middle ml-50">See properties</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template #cell(description)="data">
                        <span class="pb-1 text-truncate d-block region-content text-primary cursor-pointer text-center" @click="showModal(data.value)">
                            {{data.value}}
                        </span>
                    </template>
                    <template #cell(shortDescription)="data">
                        <span class="pb-1 text-truncate d-block region-content text-primary cursor-pointer text-center" @click="showModal(data.value)">
                            {{data.value}}
                        </span>
                    </template>
                    <template #cell(images)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <small class="font-italic" v-if="!data.value.length">
                               No image
                            </small>
                            <b-button v-else variant="outline-primary" size="sm" pill v-b-toggle.images @click="images = data.value">
                                See images
                            </b-button>
                        </span>
                    </template>
                </b-table>
            </b-overlay>
            <div class="d-flex p-1">
                <div class="w-100">
                    Page <span class="text-primary"> {{currentPage}}</span> of <span class="text-primary">{{Math.ceil(totalRows/10)}}</span>
                </div>
                <div class="w-100">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="10"
                        aria-controls="users-table"
                        align="right"
                        size="sm"
                        class="my-0"
                        @page-click="loadNewPage"
                    />
                </div>
            </div>
            <b-sidebar
                id="images"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                backdrop
                no-close-on-backdrop
                >
                <div v-for="(image, i) in images" :key="i">
                    <div class="p-1">
                        <b-img :src="image" rounded="" fluid center>

                        </b-img>
                    </div>
                </div>
            </b-sidebar>
        </b-card>
    </b-overlay>


</template>

<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Utils from '@/utils/index';
import API from './api'

const toast = useToast()
export default {
    name: 'Regions',
    components: {
        flatPickr,
        vSelect,
    },
    data() {
        return {
            date: Utils.formatDate,
            region: {
                name: '',
                frName: '',
                desc: '',
                frDesc: '',
                country: '',
                countryId: '',
            },
            countries: [],
            regionType: 1,
            regionTypes:[
                {label: 'Paid', value: true },
                {label: 'Free', value: false }
            ],
            currentPage: 1,
            totalRows: 10,
            range: '',
            searchDate: '',
            dateRange: '',
            searchName: '',
            status: 'All',
            statusOptions: [
                {'label':'Pending',"value": 1},
                {'label':'Processed',"value": 2},
                {'label':'All' },

            ],
            checked: '',
            images: [],
            items: [],
            fields: [
                {key: 'name', label: 'Name'},
                // {key: 'desc', label: 'Description'},
                {key: 'status', label: 'Visible'},
                {key: 'createdAt', label: 'Created At'},
                {key: 'updatedAt', label: 'Updated At'},
                {key: 'actions', label: 'Actions'},
            ],
            searchParams: {},
            itemBusy: false,
            pageBusy: false,
        }
    },
    watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10) {
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
    },
    created() {
        this.items = Utils.completeTable();
        this.getRegions();
        this.getCountries();
    },
    methods: {
      triggerAddRegion(){
        this.resetRegion();
        this.$bvModal.show('add-region-modal');
      },
      resetRegion(){
        this.region = {
            name: '',
            frName: '',
            desc: '',
            frDesc: '',
            country: '',
            countryId: '',
        }
      },
      processCountry(data = {}){
        return {
          value: data.id,
          countryId: data.countryId,
          label: data.name
        }
      },
      getCountries(pageNum = 1){
        API.getCountries({pageNum, pageSize: 50}).then(res=>{
          if(res.success && res.data && res.data.list) {
            if(pageNum == 1){
              this.countries = res.data.list.map(e=> this.processCountry(e));
            }else{
              this.countries.push(res.data.list.map(e=> this.processCountry(e)));
            }
            if(res.data.hasMore){
              this.getCountries(pageNum + 1);
            }
          }
        }).catch(err=>{
          console.error(err);
        });
      },
      triggerEditRegion(region){
        this.region = {...region};
        this.$bvModal.show('add-region-modal');
      },
      submittedRegion(e){
        e.preventDefault();
        if(!this.region.name || !this.region.frName){
          this.showToast('Please enter name and french name');
          return 0;
        }
        if(!this.region.country){
          this.showToast('Please select country');
          return 0;
        }

        const country = this.countries.find(r => r.value == this.region.country);
        if(!country){
          this.showToast('Please reselect country and try again');
          return 0;
        }
        this.region.countryId = country.countryId;
        // if(!this.region.desc){
        //   this.showToast('Please enter description');
        //   return 0;
        // }
        const api = this.region.id? 'editRegion': 'addRegion';
        API[api]({...this.region}).then(res => {
          if(res.success){
            if(this.region.regionId){
              this.items = this.items.map(item=>{
                if(item.regionId == this.region.regionId){
                  item = res.data;
                }
                return item;
              })
            }else{
                this.items.unshift(res.data);
                this.getRegions();
            }
            this.resetRegion();
            this.$bvModal.hide('add-region-modal');
          }
        }).catch(err=>{
          console.log(err);
        })
      },
        loadNewPage(e,page) {
            e.preventDefault();
            this.getRegions(page);
        },
        getRegions(pageNum = 1) {
            // this.pageBusy = true;
            API.getRegions({ ...this.searchParams, pageNum}).then(res => {
                this.pageBusy = false;
                if(res.success && res.data) {
                    this.currentPage = pageNum;
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total;
                }
            }).catch(() => {
                this.pageBusy = false;
                this.showToast('Failed to get regions');
            })
        },
         filterRegions() {
            if(!this.searchDate && !this.searchName && !this.status) {
                this.searchParams = {};
            }else{
                this.searchParams = {};
                if(this.searchName) {
                    this.searchParams.name = this.searchName;
                }
                if(this.status && this.status.value) {
                    this.searchParams.status = this.status.value;
                }
                if(this.searchDate && this.searchDate.from) {
                    this.searchParams.startDate = this.searchDate.from + ' 00:00:00';
                    if(!this.searchDate.to) {
                        this.this.searchDate.to = this.searchDate.from
                    }
                    this.searchParams.endDate = this.searchDate.to + ' 23:59:59';
                }
            }
            this.getRegions(1);
            this.currentPage = 1;
        },
        showToast(message, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message || 'Error fetching regions',
                    icon: icon || 'InfoIcon',
                    variant: variant || 'danger',
                },
            })
        },
        showModal(description) {
            this.$bvModal.msgBoxOk(description, {
            title: 'Region',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                // value returns true if ok was clicked
            })
            .catch(err => {
                // An error occurred
            })
        },
        triggerToggleVisibility(id) {
            this.$bvModal.msgBoxConfirm("Are you sure you want to toggle visibility", {
            title: 'Toggle',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleVisibility(id);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        toggleVisibility(id) {
          API.toggleVisibility(id).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.regionId == res.data.regionId){
                    e = res.data;
                }
                return e
              })
                // this.getRegions(this.currentPage);
                this.showToast('Property type toggled successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to toggle property type');
          })
        }
    }
}
</script>

<style lang="scss" scope>

    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .region-content{

        max-width: 100px !important;
    }
    .region-content:hover{
        transform: scale(1.2);
    }
</style>