<template>
    <b-overlay :show="pageBusy" variant="transparent">
        <b-card>
            <div class="row pt-0">
                <div class="col-12 pb-1">
                    <span class="font-weight-bolder">Filters</span>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Date Range</label>
                    <flat-pickr
                        v-model="range"
                        class="form-control"
                        :config="{ mode: 'range'}"
                        />
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Mode</label>
                    <v-select  v-model="mode"  :options="modeOptions" :clearable="false"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6" v-if="mode.value != 'submitted'">
                    <label>State</label>
                    <v-select  v-model="status" :options="statusOptions"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Company title</label>
                    <input v-model="searchTitle" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-3 col-12 col-md-6 pt-2 pt-lg-2" @click="filterCompany()">
                    <span class="btn btn-primary"> <feather-icon icon="SearchIcon" /> Search</span>
                </div>
            </div>
        </b-card>
        <b-card>
            <b-overlay :show="itemBusy">
                <div class="pb-1">
                    <b-button variant="primary" @click="triggerAddCompany">Add checklist</b-button>
                </div>
                <b-table
                    class="rounded border"
                    :items="items"
                    :fields="fields"
                    :per-page="10"
                    :current-page="1"
                    responsive
                >

                    <template #head()="scope">
                        <div class="text-nowrap text-primary">
                        {{ scope.label }}
                        </div>
                    </template>
                    <template #cell(type)="data">
                        <span class="text-nowrap badge badge-primary" v-if="data.item.id">
                           {{getCatType(data.item.companyType)}}
                        </span>
                    </template>
                    <template #cell(title)="data">
                        <span class="three-line-ellipsis" v-if="data.item.id">
                           {{data.value}}
                        </span>
                        <div v-else class="pt-2">
                        
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-badge :variant="displayStatus[1][data.value]">
                                {{ displayStatus[0][data.value] }}
                            </b-badge>
                        </span>
                    </template>
                    <template #cell()="data">
                        <div class="text-nowrap pt-1">
                            {{data.value}}
                        </div>
                    </template>
                    <template #cell(visibility)="data">
                        <div class="text-nowrap pt-1 small" v-if="data.item.id">
                            <span class="text-success" v-if="data.item.isVisible">
                                Visible
                            </span>
                            <span v-else class="text-danger">
                                Hidden
                            </span>
                            <div class="mt-1">
                                <b-badge variant="light-primary" v-if="data.item.isFeatured && data.item.isVisible">
                                    Featured checklist
                                </b-badge>
                            </div>
                            <div class="mt-1">
                                <b-badge variant="light-warning" v-if="data.item.showRequestButton">
                                    Request button visible
                                </b-badge>
                            </div>
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(updatedAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <!-- <div class="text-nowrap" v-if="data.item.id">
                            <b-button size="sm" pill variant="outline-primary" v-b-toggle.validate @click="triggerPreview(data.item)">
                        Review
                            </b-button>
                        </div> -->

                        <b-dropdown
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            v-if="data.item.id"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                ></feather-icon>
                            </template>
                            <b-dropdown-item @click="triggerPreview(data.item)">
                                <feather-icon
                                    icon="EyeIcon"
                                />
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item @click="triggerToggleVisibility(data.item.companyId)">
                                <feather-icon icon="TrashIcon"  />
                                <span class="align-middle ml-50">Toggle visibility</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="triggerToggleIsFeatured(data.item.companyId, !data.item.isFeatured)">
                                <feather-icon :icon="data.item.isFeatured? 'XIcon' : 'CheckIcon'"  />
                                <span class="align-middle ml-50">{{ data.item.isFeatured ? 'Remove from feature': 'Make featured' }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="triggerSetReqQuestions(data.item)">
                                <feather-icon icon="SettingsIcon"  />
                                <span class="align-middle ml-50">Set request question</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="triggerToggleQuestions(data.item.companyId)">
                                <feather-icon icon="SettingsIcon"  />
                                <span class="align-middle ml-50">{{ data.item.showRequestButton? 'Hide request button': 'Show request button' }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template #cell(image)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-img :src="data.value" style="height: 50px" rounded class="cursor-pointer" @click="triggerViewImage([data.value])">

                            </b-img>
                        </span>
                    </template>
                </b-table>
            </b-overlay>
            <b-modal id="view-images" ok-only hide-header size="lg" centered no-close-on-backdrop no-close-on-esc body-bg-variant="dark">
                <div style="min-height: 400px;">
                    <b-carousel id="carousel-main" style="text-shadow: 0px 0px 2px #000" :interval="7000" :controls="images.length > 1" :indicators="images.length>1" img-height="480px">
                        <b-carousel-slide class="display-image-holder" v-for="(item, index) in images" :key="index" :text="`${item}`" :img-src="item" />
                    </b-carousel>
                </div>
            </b-modal>
            <b-modal id="view-company" ok-only title="View Checklist Detail" size="xl" centered no-close-on-backdrop no-close-on-esc>
               <div class="row">
                   <div class="col-12" style="min-height: 600px;">
                       <iframe class="w-100" height="100%" :src="`${link}/preview-company/data-only/company?id=${account.id}&mode=${mode.value}`" frameborder="0"></iframe>
                       <!-- <company :company="account" /> -->
                   </div>
               </div>
            </b-modal>
            <div class="d-flex p-1">
                <div class="w-100">
                    Page <span class="text-primary"> {{currentPage}}</span> of <span class="text-primary">{{Math.ceil(totalRows/10)}}</span>
                </div>
                <div class="w-100">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="10"
                        aria-controls="users-table"
                        align="right"
                        size="sm"
                        class="my-0"
                        @page-click="loadNewPage"
                    />
                </div>
            </div>
            <b-sidebar
                shadow
                id="validate"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                backdrop
                v-model="showSidebar"
                right
                title="Checklist Approval"
                title-background="primary"
                no-close-on-backdrop
                >
                <template #footer="{ hide }">
                    <div class="d-flex align-items-center p-1">
                        <!-- @@TODO Make the address dynamic on production  -->
                        <div v-if="reviewMode == 2" class="w-100">
                            <h4 class="text-primary mt-2 pt-2 border-top">Add review</h4>
                            <div class="d-flex justify-content-between">
                                <b-form-radio v-model="applicationStatus" value="1" class="w-100">
                                    Approve
                                </b-form-radio>
                                <b-form-radio v-model="applicationStatus" value="2" class="w-100">
                                    Decline
                                </b-form-radio>
                            </div>
                            <div v-if="applicationStatus == 2" class="mt-1">
                                <label class=""> Motive </label>
                                <v-select  v-model="user.status" :clearable="false"  :options="reviewOptions"></v-select>
                                <div>
                                    <label class="mt-2"> Description </label>
                                    <b-textarea v-model="user.text">
                                    </b-textarea>
                                </div>
                            </div>
                            <div class="mt-2">
                                <b-button rounded variant="primary" @click="submitCompany(hide)" :disabled="!applicationStatus || (applicationStatus == 2 && (!user.status.value || !user.text.trim().length))">
                                    Submit
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
                <template>
                    <div>
                        <div class="p-2 border-bottom border-top">
                            <h4 class="text-primary">Checklist Information</h4>
                            <h5 class="font-weight-bolder">Title</h5>
                            <span class="text-primary font-weight-bolder">{{account.title}}</span>
                            <h5 class="font-weight-bolder mt-2">Display image</h5>
                            <b-img rounded style="max-height: 400px; max-width: 100%" :src="account.image" class="cursor-pointer" @click="triggerViewImage([account.image])">

                            </b-img>
                            <h5 class="font-weight-bolder mt-2">Category</h5>
                            <span class="text-primary font-weight-bolder">{{account.type? account.type.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">Description</h5>
                            <span class="text-primary font-weight-bolder">{{account.description}}</span>
                            <h5 class="font-weight-bolder mt-2">Price</h5>
                            <span class="font-weight-bolder">{{currency(account.price)}}</span> <small class="text-primary">XAF</small>
                            <h5 class="font-weight-bolder mt-2">Duration</h5>
                            <span class="text-primary font-weight-bolder">{{getDuration(account.duration)}}</span>
                            <h5 class="font-weight-bolder mt-2">Address</h5>
                            <span class="text-primary font-weight-bolder">{{getDuration(account.address)}}</span>
                            <h5 class="font-weight-bolder mt-2">Neighborhood</h5>
                            <span class="text-primary font-weight-bolder">{{account.neighborhood? account.neighborhood.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">City</h5>
                            <span class="text-primary font-weight-bolder">{{account.city? account.city.name: 'Unknown'}}</span>
                            <h5 class="font-weight-bolder mt-2">Other images</h5>
                            <div>
                                <div class="d-inline-block mt-1 mr-1" v-for="item in account.images" :key="item">
                                    <b-img :src="item" rounded height="100px" class="cursor-pointer" @click="triggerViewImage(account.images)">

                                    </b-img>
                                </div>
                            </div>

                        </div>
                        <h4 class="p-2 text-primary" v-if="account.reviewHistory && account.reviewHistory.length">Previous Reviews</h4>
                        <b-list-group>
                            <b-list-group-item v-for="(review, j) in account.reviewHistory" :key="j">
                                <div class="alert alert-secondary rounded p-1 mb-0">
                                    <div>
                                        <b-badge :variant="displayStatus[1][review.status]">
                                            {{ displayStatus[0][review.status] }}
                                        </b-badge>
                                    </div>
                                    <div v-if="review.status != 5">
                                        <p class="text-primary font-weight-bold">{{ review.reason[0] }}</p>
                                        {{review.message}}
                                    </div>
                                    <div class="d-flex mt-1 border-top p-0">
                                        <div class="w-100 d-flex align-items-center">
                                            <div>
                                                <b-avatar></b-avatar>
                                            </div>
                                            <div class="w-100 pl-1">
                                                <div>{{ review.name }}</div>
                                                <div>{{ review.email }}</div>
                                            </div>
                                            <!-- <small>{{review.reviewer}}</small> -->
                                        </div>
                                        <div class="text-right text-nowrap">
                                            <small class="text-primary">
                                                {{ dayjs(review.date).format("MMM D, YYYY h:mm A") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </template>
            </b-sidebar>
            <b-sidebar
                shadow
                id="validate"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                backdrop
                v-model="showAddCompanySidebar"
                right
                :title="isEdit? 'Edit checklist': 'Add checklist'"
                title-background="primary"
                no-close-on-backdrop
                >
                <template #footer="{ hide }">
                    <div class="d-flex align-items-center p-1">
                        <!-- @@TODO Make the address dynamic on production  -->
                        <div class="w-100">
                            <div class="mt-2">
                                <b-button rounded variant="primary" @click="submitCompany(hide)" :disabled="!company.country || !company.region || !company.region.length || !company.city || !company.city.length || !company.address || !company.description || !company.logo || !company.services.length || !company.contacts.length">
                                    Submit
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
                <template>
                    <div class="p-1">
                        <div class="row">
                            <div class="col-12 pt-1">
                                <label for="">Name <small class="text-danger">*</small></label>
                                <b-input v-model="company.name"></b-input>
                            </div>
                            <div class="col-12">
                                <div class="mt-1">
                                <label for="">Category</label> <small class="text-danger">*</small>
                                    <v-select
                                        v-model="category"
                                        :options="processedCategories"
                                        :reduce="e=> e.value"
                                        :clearable="false"
                                    />
                                </div>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">Description <small class="text-danger">*</small></label>
                                <b-textarea v-model="company.description"></b-textarea>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">French Description</label>
                                <b-textarea v-model="company.descriptionFr"></b-textarea>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">Address <small class="text-danger">*</small></label>
                                <b-input v-model="company.address"></b-input>
                            </div>
                            <div class="col-12">
                                <div class="mt-1">
                                    <label for="">Country</label> <small class="text-danger">*</small>
                                    <v-select
                                        v-model="company.country"
                                        :options="countries"
                                        @option:selected="resetRegion($event, true)"
                                        @option:deselected="resetRegion($event)"
                                        :reduce="e=> e.value"
                                        :clearable="false"
                                        />
                                    </div>
                                    </div>
                                    <div class="col-12">
                                    <div class="mt-1">
                                        <label for="">Region</label> <small class="text-danger">*</small>
                                        <v-select
                                            v-model="company.region"
                                            :options="regions"
                                            @option:selected="resetCity($event, true)"
                                            @option:deselected="resetCity($event)"
                                            :multiple="true"
                                            :reduce="e=> e.value"
                                        />
                                    </div>
                                    </div>
                                    <div class="col-12">
                                    <div class="mt-1">
                                        <label for="">City</label> <small class="text-danger">*</small>
                                        <v-select
                                            v-model="company.city"
                                            :reduce="e=> e.value"
                                            :options="getCompanyCitiesPerRegion"
                                            :multiple="true"
                                            @option:selected="updateCity($event, true)"
                                            @option:deselected="updateCity($event)"
                                        />
                                    </div>
                                </div>
                            <div class="col-12 pt-1">
                                <label for="">Website</label>
                                <b-input v-model="company.website"></b-input>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="services">Services <small class="text-danger">*</small></label>
                                <b-form-tags input-id="services" add-button-text="Add" placeholder="Add service" v-model="company.services"></b-form-tags>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="fr-services">French services</label>
                                <b-form-tags input-id="fr-services" add-button-text="Add" placeholder="Add service" v-model="company.servicesFr"></b-form-tags>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="phone">Contact phones <small class="text-danger">*</small></label>
                                <b-form-tags input-id="phone" add-button-text="Add" placeholder="Add phone number" v-model="company.contacts"></b-form-tags>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="email">Contact emails</label>
                                <b-form-tags input-id="email" add-button-text="Add" placeholder="Add email address" v-model="company.emails"></b-form-tags>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="cities">Operation cities <small class="text-danger">*</small></label>
                                <b-form-tags input-id="cities" add-button-text="Add" placeholder="Add city" readonly disabled v-model="company.operationCities"></b-form-tags>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">Logo <small class="text-danger">*</small></label>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mr-1 p-1 rounded bg-light">
                                        <feather-icon size="75" class="cursor-pointer" icon="UploadCloudIcon" @click="triggerImageUpload()" />
                                        <div v-if="uploader[1]">
                                            <div class="uploading-animation text-primary"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="position-relative" v-if="company.logo">
                                            <div class="position-absolute text-danger w-100 text-right" style="z-index: 10; position: absolute;">
                                                <feather-icon size="25" @click="removeImage()" class="bg-light cursor-pointer rounded" icon="XSquareIcon" />
                                            </div>
                                            <div>
                                                <img :src="company.logo" alt="" class="w-100 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">Background image</label>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mr-1 p-1 rounded bg-light">
                                        <feather-icon size="75" class="cursor-pointer" icon="UploadCloudIcon" @click="triggerImageUpload(2)" />
                                        <div v-if="uploader[2]">
                                            <div class="uploading-animation text-primary"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="position-relative" v-if="company.backgroundImage">
                                            <div class="position-absolute text-danger w-100 text-right" style="z-index: 10; position: absolute;">
                                                <feather-icon size="25" @click="removeImage(2)" class="bg-light cursor-pointer rounded" icon="XSquareIcon" />
                                            </div>
                                            <div>
                                                <img :src="company.backgroundImage" alt="" class="w-100 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-1">
                                <label for="">Gallery</label>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mr-1 p-1 rounded bg-light">
                                        <feather-icon size="75" class="cursor-pointer" icon="UploadCloudIcon" @click="triggerImageUpload(3)" />
                                    </div>
                                    <div>

                                        <div v-if="uploader[3]">
                                            <div class="uploading-animation text-primary"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="gallery-images d-inline-block mt-1 mr-1" v-for="(image, i) in company.gallery" :key="i">
                                        <div class="d-inline-block">
                                            <div class="position-relative d-line-block">
                                                <div class="position-absolute text-danger w-100 text-right" style="z-index: 10; position: absolute;">
                                                    <feather-icon size="25" @click="removeImage(3, image)" class="bg-light cursor-pointer rounded" icon="XSquareIcon" />
                                                </div>
                                                <div>
                                                    <img :src="image" alt="" height="100" class="w-100 rounded">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-sidebar>
        </b-card>
        <b-modal id="request-modal-trigger" centered size="lg" no-close-on-backdrop no-close-on-esc title="Set request data" @ok="saveRequest">
            <label for="button-title">Button text</label>
            <b-input-group id="button-title">
                <b-input v-model="request.buttonText" placeholder="English (required)"></b-input>
                <b-input v-model="request.buttonTextFr" placeholder="French"></b-input>
            </b-input-group>
            <div class="mt-2">
                <label for="">Questions</label>
            </div>
            <div style="height: 400px !important; overflow-y: auto;" class="pr-1">
                <div class="d-flex align-items-center mt-1" v-for="(item, i) in request.questions" :key="i">
                    <div class="text-danger pr-1">
                        <feather-icon icon="MinusCircleIcon" class="cursor-pointer" @click="removeQuestion(i)" />
                    </div>
                    <div class="w-100">
                        <b-input-group>
                            <b-input v-model="item.title" :id="`questions-${i}`" placeholder="English (required)"></b-input>
                            <b-input v-model="item.titleFr" placeholder="French"></b-input>
                        </b-input-group>
                    </div>
                    <div class="pl-1">
                        <b-form-checkbox name="check-button" v-model="item.isRequired" switch>
                            <div class="text-nowrap">
                                Is required ?
                            </div>
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="m-2 text-center" v-if="!request.questions || !request.questions.length">
                    <h5>No question added. Add a question or more to continue</h5>
                    <b-button size="sm" variant="primary" rounded @click="addQuestion">Add</b-button>
                </div>
            </div>
            <div class="mt-2">
                <b-button size="sm" variant="primary" rounded @click="addQuestion">Add</b-button>
            </div>
        </b-modal>
    </b-overlay>

</template>

<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import dayjs from "dayjs";
import flatPickr from 'vue-flatpickr-component'
import Utils from '@/utils/index';
import Vue from "vue";
import commonApi from '@/services/api';
import API from './api'
// import Company from './SingleCompany.vue'

const vm = new Vue({});
const toast = useToast()
export default {
    name: 'Feedback',
    components: {
        flatPickr,
        vSelect,
        // Company
    },
    data() {
        return {
            uploader: {
                3: 0,
                1: 0,
                2: 0,
            },
            countries:[],
            regions:[],
            cities:[],
            category: '',
            reviewMode: 1,
            currency: Utils.formatCurrency,
            campaignTypes:{
                '1': 'Recursive',
                '2': 'Normal',
                '3': 'Ends on target attained',
                '5': 'Never ends',
                // rejected: -10,
            },
            request: {},
            link: 'https://www.amrefinance.com',
            ftoken: '',
            dayjs: dayjs,
            currentPage: 1,
            totalRows: 10,
            range: '',
            reviews: [],
            searchDate: '',
            dateRange: '',
            searchTitle: '',
            status: '',
            durations: [
                {title: 'Hourly', value: 5 },
                {title: 'Daily', value: 6 },
                {title: 'Weekly', value: 7 },
                {title: 'Monthly', value: 8 },
                {title: 'Yearly', value: 9 },
                {title: 'One Time', value: 11 },
                {title: 'Life Time', value: 10 },
            ],
            displayStatus:[
                {
                    '1':'Pending', 
                    '5':'Approved', 
                    '-1':'Rejected', 
                    '-5':'Permanently rejected', 
                    'undefined': 'System Status'
                },
                {
                    '1':'dark', 
                    '5': 'success', 
                    '-1': 'danger', 
                    '-5': 'danger', 
                    'undefined': 'success'
                },
            ],
            statusOptions: [
                {label:'Pending',"value":1},
                {label:'Approved',"value":5},
                {label:'Rejected',"value":-1},
                {label:'Permanently Rejected',"value":-5},
            ],
            modeOptions: [
                {label:'Published',"value":'submitted'},
                {label:'Not published',"value": 'draft'},
            ],
            mode: '',
            user: {
                text:'',
                status: '',
                id: ''
            },
            account: {
                reviewHistory: []
            },
            checked: '',
            images: [],
            frMotifs: {
                '1': 'Inappropriate images',
                '2': 'Images inappropriées',
                '3': 'Compte non vérifié',
                '4': 'Informations incomplètes',
            },
            reviewOptions: [
                {label:'Invalid information',"value":1},
                {label:'Inappropriate images',"value": 2},
                {label:'Account not verified',"value": 3},
                {label:'Incomplete information',"value": 4},
                ],
            items: [],
            fields: [
                {key: 'actions', label: 'Actions'},
                {key: 'name', label: 'Name'},
                {key: 'visibility', label: 'Visibility'},
                {key: 'description', label: 'Description'},
                {key: 'logo', label: 'Display logo'},
                {key: 'createdAt', label: 'Created At'},
                {key: 'updatedAt', label: 'Updated At'},
            ],
            categories: [],
            searchParams: {},
            itemBusy: false,
            pageBusy: false,
            showSidebar: false,
            isEdit: false,
            imageUploadTarget: 1,
            company: {
                logo: '',
                website: '',
                region: [],
                regionId: [],
                country: '673fcb42a36e115ca834b052',
                countryId: 'CCLOHCDNAG9C6ZVZ',
                city: [],
                cityId: [],
                type: '',
                companyType: '',
                backgroundImage: '',
                gallery: [],
            },
            showAddCompanySidebar: false,
            retriedCategories: 0,
            applicationStatus: 0,
        }
    },
    computed: {
        processedCategories(){
            return this.categories.map(e=>{
                this.a = undefined
                return {
                    ...e,
                    label: e.name,
                    value: e.id,
                }
            }) 
        },
        getCitiesPerRegion(){
            if(!this.searchRegion) return this.cities;
            return this.cities.filter(c => c.region == this.searchRegion);
        },
        getCompanyCitiesPerRegion(){
            if(!this.company.region || !this.company.region.length) return this.cities;
            // return this.cities.filter(c => c.region == this.company.region);
            const cities = this.cities.map(c=>{
                c.canBeSelected = false;
                if(this.company.region.includes(c.region)) c.canBeSelected = true
                return c;
            })
            return cities.filter(c => c.canBeSelected == true);
        },
        getCompanyRegionsPerCountry(){
            if(!this.company.country || !this.company.country.length) return this.regions;
            return this.regions.filter(c => c.country == this.company.country);
        },
    },
    watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10) {
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
        applicationStatus(data) {
            // if(data == 1) {
            //     // this.user.status = {
            //     //     value: 3,
            //     //     label: 'Run',
            //     // }
            //     // this.user.text = '';
            // }else{
            //     this.user.status = '';
            // }
        },
        mode(data){
            // this.setStates();
            this.getCompanies();
        }
    },
    created() {
        this.items = Utils.completeTable();
        // this.getCompanies();
    },
    mounted() {
        this.getCompanies();
        this.getCategories(1);
        this.init();
    },
    methods: {
        resetCity(data, shouldUpdated = false){
            console.log(data, shouldUpdated)
            this.company.city = [];
            this.updateRegion(data, shouldUpdated );
        },
        updateRegion(data, shouldUpdated = false){
            console.log(data, shouldUpdated)
            if(shouldUpdated){
                if(!Array.isArray(data)){
                    data = [data];
                }
                this.company.regionId = data.map(e=>e.regionId);
            }
        },
        updateCity(data, shouldUpdated = false){
            console.log(data, shouldUpdated)
            if(shouldUpdated){
                if(!Array.isArray(data)){
                    data = [data];
                }
                this.company.operationCities = [];
                this.company.cityId = data.map(e=>{
                    this.company.operationCities.push(e.label);
                    return e.cityId
                });
            }
        },
        updateCountry(data, shouldUpdated = false){
            console.log(data, shouldUpdated)
            if(shouldUpdated){
                this.company.countryId = data.countryId;
            }
        },
        resetRegion(data, shouldUpdated = false){
            console.log(data, shouldUpdated)
            this.company.region = [];
            this.updateCountry(data, shouldUpdated);
            this.resetCity();
        },
        init(){
            this.getRegions();
            this.getCities();
            this.getCountries();
        },
        processCountry(data = {}){
            return {
                value: data.id,
                countryId: data.countryId,
                label: data.name
            }
        },
        getCountries(pageNum = 1){
            API.getCountries({pageNum, pageSize: 50}).then(res=>{
                if(res.success && res.data && res.data.list) {
                    if(pageNum == 1){
                        this.countries = res.data.list.map(e=> this.processCountry(e));
                    }else{
                        this.countries.push(res.data.list.map(e=> this.processCountry(e)));
                    }
                    if(res.data.hasMore){
                        this.getCountries(pageNum + 1);
                    }
                }
            }).catch(err=>{
                console.error(err);
            });
        },
        processRegion(data = {}){
            return {
                value: data.id,
                regionId: data.regionId,
                label: data.name
            }
        },
        processCity(data = {}){
            return {
                value: data.id,
                cityId: data.cityId,
                region: data.region,
                regionId: data.regionId,
                label: data.name
            }
        },
        getRegions(pageNum = 1){
            API.getRegions({pageNum, pageSize: 50}).then(res=>{
                if(res.success && res.data && res.data.list) {
                    if(pageNum == 1){
                        this.regions = res.data.list.map(e=> this.processRegion(e));
                    }else{
                        this.regions.push(res.data.list.map(e=> this.processRegion(e)));
                    }
                    if(res.data.hasMore){
                        this.getRegions(pageNum + 1);
                    }
                }
            }).catch(err=>{
                console.error(err);
            });
        },
        getCities(pageNum = 1){
            API.getCities({pageNum, pageSize: 50}).then(res=>{
                if(res.success && res.data && res.data.list) {
                    if(pageNum == 1){
                        this.cities = res.data.list.map(e=> this.processCity(e));
                    }else{
                        this.cities.push(res.data.list.map(e=> this.processCity(e)));
                    }
                    if(res.data.hasMore){
                        this.getCities(pageNum + 1);
                    }
                }
            }).catch(err=>{
                console.error(err);
            });
        },
        saveRequest(e){
            e.preventDefault();
            if(!this.request.buttonText){
                this.showToast('Please enter button text for English (required)');
                return;
            }
            for(let i = 0; i < this.request.questions.length; i++){
                if(!this.request.questions[i].title){
                    document.getElementById(`questions-${i}`).focus();
                    this.showToast('Please enter questions for all opened boxes')
                    return;
                }
            }

            const params = {
                buttonTextFr: this.request.buttonTextFr || this.request.buttonText,
                buttonText: this.request.buttonText,
                companyId: this.request.companyId,
                questions: this.request.questions
            }
            
            API.submitQuestion(params).then(res=>{
                if(res.success && res.data) {
                    this.resetCompany();
                    setTimeout(()=>{
                        this.getCompanies(1);
                    },200);
                    this.showToast('Questions added successfully','CheckCircle','success');
                    this.$bvModal.hide('request-modal-trigger');
                }
            }).catch(err=>{
                console.log(err);
            })

        },
        removeQuestion(index){
            this.request.questions = this.request.questions.filter((e,r)=> r != index );
        },
        addQuestion(){
            this.request.questions.push({title: '', isRequired: false});
            this.request = {...this.request};
        },
        triggerSetReqQuestions(request){
            this.request = JSON.parse(JSON.stringify(request));
            //this.request.questions = [];
            this.$bvModal.show('request-modal-trigger')
        },
        triggerAddCompany(){
            this.resetCompany();
            this.showAddCompanySidebar = true;
        },
        triggerToggleVisibility(id) {
            this.$bvModal.msgBoxConfirm("Are you sure you want to toggle visibility", {
            title: 'Toggle',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleVisibility(id);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        triggerToggleIsFeatured(companyId, isFeatured) {
            const msg = isFeatured? "Are you sure you want to remove this checklist from featured checklists?" : "Are you sure you want to add this checklist to featured checklists?";
            this.$bvModal.msgBoxConfirm(msg, {
            title: 'Toggle',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleFeatured(companyId, isFeatured);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        triggerToggleQuestions(id) {
            this.$bvModal.msgBoxConfirm("Are you sure you want to toggle questions visibility?", {
            title: 'Toggle questions visibility',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleQuestionsVisibility(id);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        toggleQuestionsVisibility(id) {
          API.toggleQuestionsVisibility(id).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.companyId == res.data.companyId){
                    e = res.data;
                }
                return e
              })
                this.showToast('Questions Visibility toggled successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to toggle questions visibility');
          })
        },
        toggleVisibility(id) {
          API.toggleVisibility(id).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.companyId == res.data.companyId){
                    e = res.data;
                }
                return e
              })
                this.showToast('Visibility toggled successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to toggle visibility');
          })
        },
        toggleFeatured(companyId, isFeatured) {
          API.toggleFeaturedVisibility(companyId, {isFeatured}).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.companyId == res.data.companyId){
                    e = res.data;
                }
                return e
              })
                this.showToast('Featured status updated successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to update featured status');
          })
        },
        removeImage(target = 1, image = ''){
            if(target == 1){
                this.company.logo = '';
            }else if(target == 2){
                this.company.backgroundImage = '';
            }else if(target == 3){
                this.company.gallery =  this.company.gallery.filter(e=> e != image)
            }
        },
        triggerImageUpload(source = 1){ // 1 for logo 2 for background image, 3 for gallery
            this.imageUploadTarget = source;
            let uploader = document.getElementById('image-uploader');
            if(!uploader){
                uploader = document.createElement('input');
                uploader.setAttribute('type', 'file');
                uploader.setAttribute('id', 'image-uploader');
                uploader.setAttribute('accept', '.jpg, .png, .webp');
                uploader.onchange = e=>{
                    if(source < 3){
                        this.uploader[source]+=1
                        this.uploadImage(uploader.files[0], source);
                    }else{
                        for(let i = 0; i < uploader.files.length; i++){
                            this.uploader[source]+=1
                            this.uploadImage(uploader.files[i], source);
                        }
                    }
                }
            }
            uploader.setAttribute('multiple', source == 3 );
            uploader.click();
        }, 
        uploadImage(image, target){ 
            commonApi.imageUpload(image).then(res=>{
                this.uploader[target] -= 1;
                if(res.success){
                    console.log(target, this.uploader, this.uploader[target])
                    if(target == 1){
                        this.company.logo = res.data[0]
                    }else if(target == 2){
                        this.company.backgroundImage = res.data[0]
                    }else if(target == 3){
                        this.company.gallery.push(...res.data);
                    }
                }
            }).catch(err=>{
                this.uploader[target] -= 1;
                console.log(err)
            })
        },
        getDuration(e){
            const duration = this.durations.find(d => d.value == e);
            if(duration){
                return duration.title
            }
            return "";
        },
        getCatType(id){
            const categories = this.categories.find(c=> c.typeId == id);
            if(categories) return categories.name;
            return '';
        },
        getCategories(pageNum = 1) {
            // this.pageBusy = true;
            if(this.retriedCategories > 10){
                return;
            }
            API.getCategories({ pageSize: 100, pageNum}, false, true).then(res => {
                if(res.success && res.data) {

                    this.retriedCategories = 0;
                    this.categories.push(...res.data.list);
                    if(res.data.hasMore){
                        this.getCategories(pageNum + 1);
                    }
                }
            }).catch(() => {
                this.retryGetCategoriesId(pageNum);
            })
        },
        retryGetCategoriesId(page){
            this.retriedCategories+=1;
            setTimeout(() => {
                this.getCategories(page);
            }, 10000);
        },
        setStates(){
            if(this.mode.value == 'draft'){
                this.statusOptions = [
                    // {label:'Not submitted',"value":1},
                    {label:'Pending',"value":1},
                    {label:'Approved',"value":5},
                    // {label:'Initialized',"value":20},
                    {label:'Rejected',"value":-1},
                    {label:'Permanently Rejected',"value":-1},
                ]
            }else{
                this.statusOptions = [
                    {label:'Approved',"value":100},
                    {label:'Running',"value":300}, // running
                    {label:'Paused',"value":400},
                    {label:'Stopped',"value":-300},
                    {label:'Pending Deletion',"value":-500},
                ]
            }
            this.status = '';
        },
        showCompany(data = {}) {
            this.account = data;
            this.$bvModal.show('view-company');
        },
        triggerViewImage(image = []) {
            this.images = image;
            this.$bvModal.show('view-images');
        },
        deleteCompany(e) {
            API.deleteCompany({id: this.account.id}).then(res=>{
                if(res.success) {
                    this.user.status = {};
                    this.user.text = '';
                    setTimeout(()=>{
                        this.getCompanies(this.currentPage);
                    },200);
                    this.showSidebar = false;
                    this.showToast('Checklist deleted successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        submitCompany(e) {
            let request = API.addCompany;
            if(this.company.id){
                request = API.editCompany
            }
            const category = this.processedCategories.find(c => c.value == this.category);
            if(category){
                this.company.companyType = category.typeId
                this.company.type = category.value
            }else{
                this.showToast('Select type');
                return false;
            }

            request(this.company).then(res=>{
                if(res.success && res.data) {
                    this.resetCompany();
                    setTimeout(()=>{
                        this.getCompanies(1);
                    },200);
                    e();
                    this.showToast('Checklist Added successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        resetCompany(){
            this.request = {
                questions: [],
            }
            this.company = {
                logo: '',
                website: '',
                region: [],
                regionId: [],
                country: '673fcb42a36e115ca834b052',
                countryId: 'CCLOHCDNAG9C6ZVZ',
                city: [],
                cityId: [],
                type: '',
                companyType: '',
                backgroundImage: '',
                gallery: []
            }
        },
        triggerPreview(data) {
            this.company = data;
            this.category = data.type;
            this.showAddCompanySidebar = true;
        },
        loadNewPage(e,page) {
            console.log(page)
            e.preventDefault();
            this.getCompanies(page);
        },
        getCompanies(pageNum = 1) {
            // this.pageBusy = true;
            API.getCompanies({...this.searchParams, pageNum, mode: this.mode.value || 'submitted'}).then(res => {
                this.pageBusy = false;
                if(res.success && res.data) {
                    this.currentPage = res.data.currentPage;
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total || 10;
                }
            }).catch(() => {
                this.pageBusy = false;
                this.showToast();
            })
        },
         filterCompany() {
            
            this.searchParams = {};
            if(this.searchTitle) {
                this.searchParams.name = this.searchTitle;
            }
            if(this.status && this.status.value && this.mode.value != 'submitted') {
                this.searchParams.status = this.status.value;
            }
            if(this.searchDate && this.searchDate.from) {
                this.searchParams.startDate = this.searchDate.from + ' 00:00:00';
                if(!this.searchDate.to) {
                    this.this.searchDate.to = this.searchDate.from
                }
                this.searchParams.endDate = this.searchDate.to + ' 23:59:59';
            }
            
            this.getCompanies(1);
            this.currentPage = 1;
        },
        showToast(message, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message || 'Error',
                    icon: icon || 'InfoIcon',
                    variant: variant || 'danger',
                },
            })
        },
        showModal(feedback) {
            this.$bvModal.msgBoxOk(feedback, {
            title: 'Feedback',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.feedback = value
            })
            .catch(err => {
                // An error occurred
            })
        },
        markAsProcessed(feedbackId) {
            if(feedbackId) {
                this.feedbacks = {};
                // this.pageBusy = true;
                API.markAsProcessed({_id: feedbackId}).then(res => {
                    this.pageBusy = false;
                    if(res.success) {
                        this.items = this.items.map(item =>{
                            if(item.id == feedbackId) {
                                item.status = res.data.status;
                            }
                            return item;
                        })
                        this.showToast('Feedback Marked as processed', 'CheckIcon', 'success');
                    }
                }).catch(() => {
                    this.pageBusy = false;
                    this.showToast('Error processing feedback')
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>

    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .feedback-content{

        max-width: 100px !important;
    }
    .feedback-content:hover{
        transform: scale(1.2);
    }
    #carousel-main{
        text-align: center !important;
        img{
            height: 480px !important;
            width: auto !important;
            margin: auto
        }
    }
</style>